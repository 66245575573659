<template>
  <v-col
    cols="12"
    sm="6"
    md="4"
    lg="3"
  >
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 12 : 2"
        :class="{ 'on-hover': hover }"
        :to="{name: 'Productos', query: {filtro: `M+${marca.dato}`}}" 
        class="pa-2">
        <v-img
          :src="marca.imagen"
          :alt="marca.dato"
          :class="{ 'on-hover': hover }"
          class="img-prod-transform" 
          height="125"
          max-height="125"
          contain
        ></v-img>
        <v-card-title 
          v-if="!marca.imagen"
          class="title">
          {{ marca.dato }}
        </v-card-title>
      </v-card>
    </v-hover>
  </v-col>
</template>

<script>
export default {
  props: {
    marca: {
      type: Object
    }
  }
}
</script>

<style scoped>

.img-prod-transform {
  transition: transform .2s;
}

.img-prod-transform:hover {
  cursor: pointer;
  transform: scale(1.02);
}
</style>